import { useState, useEffect, useRef } from "react";
import { Navbar } from "../widgets/Navbar";
import { Header } from "../widgets/Header";
import { Footer } from "../widgets/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { LinkItem } from "./partials/LinkItem";
import moment from "moment";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";
import {
  metaRequestInsert,
  getTotalUsdt,
  getTransaction,
  generateReferral,
  getStageData,
} from "../services/transaction";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import $ from "jquery";
import {
  ethChainId,
  binanceChainId,
  bscRpcUrl,
  EthRpcUrl,
  wbtc20abi,
  usdterc20abi,
  usdcerc20abi,
  tokenAddress,
  tokenAbi,
  polygonChainId,
  arbitrumChainId,
  polygonRpcUrl,
  arbitrumRpcUrl,
  explorerUrlBsc,
  explorerUrlEth,
  explorerUrlArbitrum,
  explorerUrlAvalanche,
  avalancheChainId,
  avalancheRpcUrl,
  usdtPolygonabi,
  usdcPolygonabi,
  usdtArbitrumAbi,
  usdcArbitrumAbi,
  usdtAvalancheAbi,
  usdcAvalancheAbi,
  usdtPolygon,
  usdcPolygon,
  usdtArbitrum,
  usdcArbitrum,
  usdtAvalanche,
  usdcAvalanche,
  crudoPolygonAddress,
  crudoArbitrumAddress,
  crudoAvalancheAddress,
  crudoPolygonabi,
  crudoArbitrumabi,
  crudoAvalancheabi,
} from "../../constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  wbtc,
  weth,
  usdt,
  usdc,
  bep20abi,
  crudoAddress,
  crudoabi,
  crudoEthAddress,
  crudoEthabi,
  erc20abi,
  wbtcErc,
  usdtErc,
  usdcErc,
} from "../../constant";
import {
  getUSerReferral,
  registerUser,
  getTopReferrals,
} from "../services/user";
import { checkUser, authUser } from "../services/auth/auth";
import { Emailpattern } from "../pattern/Pattern";
import { copyUrl } from "../Constant/BaseUrl";
import SwiperSlider from "./SwiperSlider";

export const Home = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = "1d8e553b04494fc86b01065d081d9e93";
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [bnbPrice, setbnbPrice] = useState();
  const [btcPrice, setbtcPrice] = useState();
  const [bonus, setBonus] = useState("");
  const [tokenPrice, settokenPrice] = useState();
  const [ethPrice, setethPrice] = useState();
  const [totalUsdt, setTotalUsdt] = useState();
  const [totalToken, setTotalToken] = useState();

  const [totalSupply, setTotalSupply] = useState();
  const [referral_code, setReferral_code] = useState();
  const [stageRecord, setStageRecord] = useState([]);
  const [tokenLivePrice, setTokenLivePrice] = useState(0);

  // console.log(tokenLivePrice,"tokenLivePrice")
  const [selectedCurrencyUserBalance, setselectedCurrencyUserBalance] =
    useState();
  const [selectedChain, setSelectedChain] = useState(0);
  const [name, setName] = useState("");
  const [mobile_number, setMobile_number] = useState("+44");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  const [mobile_numberErr, setMobile_numberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [country_code, setCountry_code] = useState();
  const [reffer_from, setReffer_from] = useState("");
  const [copyText, setCopyText] = useState("Copy Link");
  const [selectChains, setSelectChain] = useState("0");
  const [selectTeb, setSelectTeb] = useState("1");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [referralBonus, setReferralBonus] = useState();
  const [value, setValue] = useState(1000);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTokenAmt, setTotalTokenAmt] = useState(0);
  const [stages, setStages] = useState(0);
  const [numericVal, setNumericVal] = useState("");
  const [numericTokenVal, setNumericTokenVal] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [mainloaderStatus, setMainLoaderStatus] = useState(false);
  const [topReferralData, setTopReferralData] = useState([]);

  console.log(totalToken, "totalToken=======");
  const handleChangeForRange = (event) => {
    setValue(event.target.value);
  };
  const [tokenInput, settokenInput] = useState();
  const [ukcInput, setukcInput] = useState();

  const [buyBtnText, setbuyBtnText] = useState("Select Currency");

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: explorerUrlEth,

      rpcUrl: EthRpcUrl,
    },
    {
      chainId: polygonChainId,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: explorerUrlEth,

      rpcUrl: polygonRpcUrl,
    },
    // {
    //   chainId: arbitrumChainId,
    //   name: "Arbitrum Sepolia Testnet",
    //   currency: "ETH",
    //   explorerUrl: explorerUrlArbitrum,

    //   rpcUrl: arbitrumRpcUrl,
    // },
    // {
    //   chainId: avalancheChainId,
    //   name: "Avalanche",
    //   currency: "AVAX",
    //   explorerUrl: explorerUrlAvalanche,

    //   rpcUrl: avalancheRpcUrl,
    // },
  ];

  const bscRpc = bscRpcUrl;
  const EthRpc = EthRpcUrl;
  const polygonRpc = polygonRpcUrl;
  const arbitrumRpc = arbitrumRpcUrl;
  const avalancheRpc = avalancheRpcUrl;

  const metadata = {
    name: "Crudo",
    description: "crudo",
    url: "https://crudoprotocol.com/",
    icons: ["https://crudoprotocol.com/html/images/crudo_Logo.svg"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const selectAddress =
    selectChains == 0
      ? crudoAddress
      : selectChains == 1
      ? crudoEthAddress
      : selectChains == 2
      ? crudoPolygonAddress
      : selectChains == 3
      ? crudoArbitrumAddress
      : selectChains == 4
      ? crudoAvalancheAddress
      : "";
  const selectAbi =
    selectChains == 0
      ? crudoabi
      : selectChains == 1
      ? crudoEthabi
      : selectChains == 2
      ? crudoPolygonabi
      : selectChains == 3
      ? crudoArbitrumabi
      : selectChains == 4
      ? crudoAvalancheabi
      : "";

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const selectCurrency = async (e) => {
    try {
      if (isConnected) {
        setSelectedCurrency(e.target.value);
        let getTokenAddr;
        let provider;
        if (selectChains == 0) {
          provider = new JsonRpcProvider(bscRpc);
        } else if (selectChains == 1) {
          provider = new JsonRpcProvider(EthRpc);
        } else if (selectChains == 2) {
          provider = new JsonRpcProvider(polygonRpc);
        } else if (selectChains == 3) {
          provider = new JsonRpcProvider(arbitrumRpc);
        } else if (selectChains == 4) {
          provider = new JsonRpcProvider(avalancheRpc);
        }

        const signer = await getSignerOrProvider(true);
        if (e.target.value == "") {
          setselectedCurrencyUserBalance("");
          setbuyBtnText("Select Currency");
        } else {
          setbuyBtnText("Approve & Buy");
        }

        if (selectChains == 0) {
          if (e.target.value == "1") {
            getTokenAddr = wbtc;
          } else if (e.target.value == "2") {
            getTokenAddr = weth;
          } else if (e.target.value == "3") {
            getTokenAddr = usdt;
          } else if (e.target.value == "4") {
            getTokenAddr = usdc;
          }
        } else if (selectChains == 1) {
          if (e.target.value == "1") {
            getTokenAddr = wbtcErc;
          } else if (e.target.value == "2") {
            getTokenAddr = usdtErc;
          } else if (e.target.value == "3") {
            getTokenAddr = usdcErc;
          }
        } else if (selectChains == 2) {
          if (e.target.value == "1") {
            getTokenAddr = usdtPolygon;
          } else if (e.target.value == "2") {
            getTokenAddr = usdcPolygon;
          }
        } else if (selectChains == 3) {
          if (e.target.value == "1") {
            getTokenAddr = usdtArbitrum;
          } else if (e.target.value == "2") {
            getTokenAddr = usdcArbitrum;
          }
        } else if (selectChains == 4) {
          if (e.target.value == "1") {
            getTokenAddr = usdtAvalanche;
          } else if (e.target.value == "2") {
            getTokenAddr = usdcAvalanche;
          }
        }

        setukcInput("");
        settokenInput("");

        if (e.target.value == "0") {
          const balance = await provider.getBalance(address);
          const balanceInEther = formatEther(balance);

          setselectedCurrencyUserBalance(balanceInEther);
        } else {
          let chainnnn;
          if (selectChains == 0) {
            chainnnn = bep20abi;
          } else if (selectChains == 1) {
            if (e.target.value == 0) {
              chainnnn = erc20abi;
            } else if (e.target.value == 1 || e.target.value == 3) {
              chainnnn = wbtc20abi;
            } else if (e.target.value == 2) {
              chainnnn = usdterc20abi;
            }
          } else if (selectChains == 2) {
            if (e.target.value == 0) {
              chainnnn = erc20abi;
            } else if (e.target.value == 1) {
              chainnnn = usdtPolygonabi;
            } else if (e.target.value == 2) {
              chainnnn = usdcPolygonabi;
            }
          } else if (selectChains == 3) {
            if (e.target.value == 0) {
              chainnnn = erc20abi;
            } else if (e.target.value == 1) {
              chainnnn = usdtArbitrumAbi;
            } else if (e.target.value == 2) {
              chainnnn = usdcArbitrumAbi;
            }
          } else if (selectChains == 4) {
            if (e.target.value == 0) {
              chainnnn = erc20abi;
            } else if (e.target.value == 1) {
              chainnnn = usdtAvalancheAbi;
            } else if (e.target.value == 2) {
              chainnnn = usdcAvalancheAbi;
            }
          }
          let balance;

          if (selectChains == 0) {
            const contract = new Contract(getTokenAddr, chainnnn, provider);
            balance = await contract.balanceOf(address);
          } else if (selectChains == 1) {
            if (e.target.value == 0) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);
              balance = await contract?.balanceOf(address);
            } else if (e.target.value == 1 || e.target.value == 3) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);

              balance = await contract?.balanceOf(address);
            } else if (e.target.value == 2) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);
              balance = await contract?.balances(address);
            }
          } else if (
            selectChains == 2 ||
            selectChains == 3 ||
            selectChains == 4
          ) {
            if (e.target.value == 0) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);
              balance = await contract?.balanceOf(address);
            } else if (e.target.value == 1 || e.target.value == 2) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);

              balance = await contract?.balanceOf(address);
            }
          }

          balance = Number(balance);

          if (selectChains == "1") {
            let newUpdateBal;
            if (e.target.value == "0") {
              const hnewUpdateBal = balance / 10 ** 18;
              setselectedCurrencyUserBalance(hnewUpdateBal);
            } else if (e.target.value == "1") {
              newUpdateBal = balance / 10 ** 8;
            } else if (e.target.value == "2" || e.target.value == "3") {
              newUpdateBal = balance / 10 ** 6;
            }
            newUpdateBal = parseFloat(newUpdateBal ? newUpdateBal : 0).toFixed(
              2
            );
            setselectedCurrencyUserBalance(newUpdateBal);
          } else if (selectChains == "0") {
            let nbalance = balance / 10 ** 18;
            nbalance = nbalance?.toFixed(2);
            setselectedCurrencyUserBalance(nbalance);
          } else if (
            selectChains == "2" ||
            selectChains == "3" ||
            selectChains == "4"
          ) {
            let nbalance = balance / 10 ** 6;
            nbalance = nbalance?.toFixed(2);
            setselectedCurrencyUserBalance(nbalance);
          }

          setbuyBtnText("Approve & Buy");
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  wallet");
      }
    } catch (err) {
      // console.error(err);
    }
  };
  // ============= eth chain======//
  const selectChain = async (val) => {
    setSelectChain(val);
    setSelectedCurrency("");
    try {
      if (isConnected) {
        const provider = new JsonRpcProvider(bscRpc);

        const signer = await getSignerOrProvider(true);
        setukcInput("");
        settokenInput("");
        if (val == "") {
          setselectedCurrencyUserBalance("");
          setbuyBtnText("Select Chain");
        } else {
          setbuyBtnText("Approve & Buy");
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  wallet");
      }
    } catch (err) {
      // console.error(err);
    }
  };
  const selectTebs = async (val) => {
    // console.log(val);
    setSelectTeb(val);
  };

  // ============= eth chain======//
  const getBalance = async () => {
    let provider;
    if (selectChains == 0) {
      provider = new JsonRpcProvider(bscRpc);
    } else if (selectChains == 1) {
      provider = new JsonRpcProvider(EthRpc);
    } else if (selectChains == 2) {
      provider = new JsonRpcProvider(polygonRpc);
    } else if (selectChains == 3) {
      provider = new JsonRpcProvider(arbitrumRpc);
    } else if (selectChains == 4) {
      provider = new JsonRpcProvider(avalancheRpc);
    }

    const signer = await getSignerOrProvider(true);
    let balance = 0;
    if (selectedCurrency == null) {
      balance = 0;
    } else if (selectedCurrency == 0) {
      balance = await provider.getBalance(address);
      balance = formatEther(balance);
    } else {
      let getTokenAddr;
      let chainnnn;

      if (selectChains == 0) {
        if (selectedCurrency == "1") {
          getTokenAddr = wbtc;
        } else if (selectedCurrency == "2") {
          getTokenAddr = weth;
        } else if (selectedCurrency == "3") {
          getTokenAddr = usdt;
        } else if (selectedCurrency == "4") {
          getTokenAddr = usdc;
        }
        chainnnn = bep20abi;
      } else if (selectChains == 1) {
        if (selectedCurrency == "1") {
          chainnnn = wbtc20abi;
          getTokenAddr = wbtcErc;
        } else if (selectedCurrency == "2") {
          chainnnn = usdterc20abi;
          getTokenAddr = usdtErc;
        } else if (selectedCurrency == "3") {
          getTokenAddr = usdcErc;
          chainnnn = wbtc20abi;
        }
      } else if (selectChains == 2) {
        if (selectedCurrency == "1") {
          chainnnn = usdtPolygon;
          getTokenAddr = usdtPolygonabi;
        } else if (selectedCurrency == "2") {
          chainnnn = usdcPolygon;
          getTokenAddr = usdcPolygonabi;
        }
      } else if (selectChains == 3) {
        if (selectedCurrency == "1") {
          chainnnn = usdtArbitrumAbi;
          getTokenAddr = usdtArbitrum;
        } else if (selectedCurrency == "2") {
          chainnnn = usdcArbitrumAbi;
          getTokenAddr = usdcArbitrum;
        }
      } else if (selectChains == 4) {
        if (selectedCurrency == "1") {
          chainnnn = usdtAvalancheAbi;
          getTokenAddr = usdtAvalanche;
        } else if (selectedCurrency == "2") {
          chainnnn = usdcAvalancheAbi;
          getTokenAddr = usdcAvalanche;
        }
      }
      if (selectChains == 0) {
        const contract = new Contract(getTokenAddr, chainnnn, provider);
        balance = await contract.balanceOf(address);
      } else if (selectChains == 1) {
        if (selectedCurrency == 0) {
          const contract = new Contract(getTokenAddr, chainnnn, provider);
          balance = await contract?.balanceOf(address);
        } else if (
          (selectedCurrency == 1 && address) ||
          (selectedCurrency == 3 && address)
        ) {
          const contract = new Contract(getTokenAddr, chainnnn, provider);
          balance = await contract?.balanceOf(address);
        } else if (selectedCurrency == 2 && address) {
          const contract = new Contract(getTokenAddr, chainnnn, provider);
          balance = await contract?.balances(address);
        }
      }

      balance = Number(balance);

      balance = balance / 10 ** 18;
      balance = balance.toFixed(4);

      if (selectChains == 2 || selectChains == 3 || selectChains == 4) {
        if (selectedCurrency == 0) {
          const contract = new Contract(getTokenAddr, chainnnn, provider);
          balance = await contract?.balanceOf(address);
        }
      }
      balance = balance / 10 ** 6;
      balance = balance.toFixed(4);
    }
    return balance;
  };

  useEffect(() => {
    const chain = localStorage.getItem("chain");
    const currency = localStorage.getItem("currency");

    const tokenInput = localStorage.getItem("tokenInput");
    const ukcInput = localStorage.getItem("ukcInput");
    const selectedCurrencyUserBalance = localStorage.getItem(
      "selectedCurrencyUserBalance"
    );
    if (chain) {
      setSelectChain(chain);

      localStorage.removeItem("chain");
    }
    if (currency) {
      setSelectedCurrency(currency);
      localStorage.removeItem("currency");
    }
    if (tokenInput) {
      settokenInput(tokenInput);
      localStorage.removeItem("tokenInput");
    }
    if (selectedCurrencyUserBalance) {
      setselectedCurrencyUserBalance(selectedCurrencyUserBalance);
      localStorage.removeItem("selectedCurrencyUserBalance");
    }
    if (ukcInput) {
      let amt = Number(ukcInput).toFixed(2);
      setukcInput(amt);
      setbuyBtnText("Approve & Buy");
      localStorage.removeItem("ukcInput");
    }
  }, [localStorage]);

  const trnsactionGet = async () => {
    if (address) {
      const resp = await getTransaction({ address });

      if (resp.status) {
        setBonus(resp?.bonus);
        const referralAmt = Number(resp?.referralAmount).toFixed(2);
        // console.log ( referralAmt,"referralAmt",resp?.referralAmount)
        setReferralBonus(referralAmt);
        setReffer_from(resp?.reffer_from);
      }
    }
  };
  function formatWalletAddress(address) {
    if (!address || address.length < 20) {
      return address;
    }
    const obfuscatedAddress = `${address.slice(0, 4)}***${address.slice(-4)}`;
    return obfuscatedAddress;
  }

  const getTopRankReferral = async () => {
    const resp = await getTopReferrals();

    if (resp.status) {
      const formattedData = resp.data.map((item) => ({
        ...item,
        wallet_address: formatWalletAddress(item.wallet_address),
        total_referral_amt: Number(item.total_referral_amt).toFixed(2),
      }));
      setTopReferralData(formattedData);
    }
  };

  useEffect(() => {
    getTopRankReferral();
  }, []);
  useEffect(() => {
    getTotalSupply();
    trnsactionGet();
  }, [address]);

  const formatNumber = (number) => {
    return number !== null && number !== undefined
      ? number.toLocaleString("en-US")
      : "";
  };

  let formattedTotalToken = formatNumber(Number(totalToken) + 26250000);
  let formattedTotalTokenStage2 = formatNumber(
    Number(totalToken) + 26250000 - 40000000 - 40000000 - 40000000-40000000
  );

  let formattedTotalUsdt = formatNumber(Number(totalUsdt) + 211186);
  // - 14883-52617-469444

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  const buy = async () => {
    try {
      if (isConnected) {
        if (!selectChains) {
          toast.dismiss();
          toast.error("Please select chain");
          return;
        }

        if (!selectedCurrency) {
          toast.dismiss();
          toast.error("Please select Currency");

          return;
        }

        // try {
        if (
          tokenInput == undefined ||
          tokenInput.length == 0 ||
          tokenInput <= 0
        ) {
          toast.dismiss();
          toast.error("Invalid Amount");

          return;
        }

        if (selectedCurrency == "0") {
          var tokenBalance = await getBalance();

          if (tokenBalance < tokenInput) {
            toast.dismiss();
            toast.error("Insufficient Balance");

            return;
          }

          const signer = await getSignerOrProvider(true);
          const provider = await getSignerOrProvider();

          if (selectChains == 0) {
            if (chainId != binanceChainId) {
              toast.dismiss();
              toast.error("Please change network to binanace smart chain");
              return;
            }
          } else if (selectChains == 1) {
            if (chainId != ethChainId) {
              toast.dismiss();
              toast.error("Please change network to  Eth chain");
              return;
            }
          } else if (selectChains == 2) {
            if (chainId != polygonChainId) {
              toast.dismiss();
              toast.error("Please change network to  Polygon chain");
              return;
            }
          } else if (selectChains == 3) {
            if (chainId != arbitrumChainId) {
              toast.dismiss();
              toast.error("Please change network to  Arbitrum chain");
              return;
            }
          } else if (selectChains == 4) {
            if (chainId != avalancheChainId) {
              toast.dismiss();
              toast.error("Please change network to  Avalanche chain");
              return;
            }
          }

          const res = await checkUser({ address });
          if (res.status) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            const contract = new Contract(selectAddress, selectAbi, provider);
            const valueInWei = parseEther(tokenInput.toString());

            let balance;
            if (selectChains == 0) {
              balance = await contract.buyTokenWithbnb(
                process.env.REACT_APP_getfundBinanceAddress,
                {
                  value: valueInWei,
                }
              );
            } else if (selectChains == 1) {
              balance = await contract.buyTokenWithETH(
                process.env.REACT_APP_getfundEthreumAddress,
                {
                  value: valueInWei,
                }
              );
            } else if (selectChains == 2) {
              balance = await contract.buyTokenWithMATIC(
                process.env.REACT_APP_getfundEthreumAddress,
                {
                  value: valueInWei,
                }
              );
            } else if (selectChains == 3) {
              balance = await contract.buyTokenWithArbitrumETH(
                process.env.REACT_APP_getfundEthreumAddress,
                {
                  value: valueInWei,
                }
              );
            } else if (selectChains == 4) {
              balance = await contract.buyTokenWithAVAX(
                process.env.REACT_APP_getfundEthreumAddress,
                {
                  value: valueInWei,
                }
              );
            }

            const transactionHash = balance.hash;
            // const reffer_from = localStorage.getItem("referral_code");
            const data = {
              userAddress: address,
              chain: selectChains,
              currency: selectedCurrency,
              amount: tokenInput,
              tokenAmount: ukcInput,
              trans_id: transactionHash,
              reffer_from: reffer_from,
            };
            const token = localStorage.getItem("jwtToken");
            const result = await metaRequestInsert(data, token);

            if (result.status) {
              window.removeEventListener("beforeunload", handleBeforeUnload);
              toast.dismiss("");
              toast.success(result.message);

              setTimeout(() => {
                window.location.reload();
              }, [10000]);
            } else {
              toast.dismiss("");
              toast.dismiss(result.message);
            }
          } else {
            toast.error(res.message);
            localStorage.setItem("chain", selectChains);
            localStorage.setItem("currency", selectedCurrency);
            localStorage.setItem("tokenInput", tokenInput);
            localStorage.setItem("ukcInput", ukcInput);
            localStorage.setItem(
              "selectedCurrencyUserBalance",
              selectedCurrencyUserBalance
            );
            setTimeout(() => {
              navigate("/sign-up");
            }, 2000);
          }
        } else {
          const signer = await getSignerOrProvider(true);
          const provider = await getSignerOrProvider();
          const inputamount = parseUnits(tokenInput, 18);

          let getTokenAddr;
          let updatetAmt = Number(tokenInput);
          let chainnnn;
          if (selectChains == 0) {
            if (chainId != binanceChainId) {
              toast.dismiss();
              toast.error("Please change network to binanace smart chain");
              return;
            }
            if (selectedCurrency == "1") {
              getTokenAddr = wbtc;
            } else if (selectedCurrency == "2") {
              getTokenAddr = weth;
            } else if (selectedCurrency == "3") {
              getTokenAddr = usdt;
            } else if (selectedCurrency == "4") {
              getTokenAddr = usdc;
            }
            chainnnn = bep20abi;
          } else if (selectChains == 1) {
            if (chainId != ethChainId) {
              toast.dismiss();
              toast.error("Please change network to  Eth chain");
              return;
            }
            await getBalance();

            if (selectedCurrency == "1") {
              getTokenAddr = wbtcErc;
              chainnnn = wbtc20abi;
              updatetAmt = updatetAmt * 10 ** 8;
            } else if (selectedCurrency == "2") {
              chainnnn = usdterc20abi;

              getTokenAddr = usdtErc;
              updatetAmt = updatetAmt * 10 ** 6;
            } else if (selectedCurrency == "3") {
              chainnnn = usdcerc20abi;
              getTokenAddr = usdcErc;
              updatetAmt = updatetAmt * 10 ** 6;
            }
          } else if (selectChains == 2) {
            if (chainId != polygonChainId) {
              toast.dismiss();
              toast.error("Please change network to  Polygon chain");
              return;
            }
            await getBalance();

            if (selectedCurrency == "1") {
              getTokenAddr = usdtPolygon;
              chainnnn = usdterc20abi;
              updatetAmt = updatetAmt * 10 ** 6;
            } else if (selectedCurrency == "2") {
              chainnnn = usdcPolygonabi;

              getTokenAddr = usdcPolygon;
              updatetAmt = updatetAmt * 10 ** 6;
            }
          } else if (selectChains == 3) {
            if (chainId != arbitrumChainId) {
              toast.dismiss();
              toast.error("Please change network to  Arbitrum chain");
              return;
            }
            await getBalance();

            if (selectedCurrency == "1") {
              getTokenAddr = usdtArbitrum;
              chainnnn = usdtArbitrumAbi;
              updatetAmt = updatetAmt * 10 ** 6;
            } else if (selectedCurrency == "2") {
              getTokenAddr = usdcArbitrum;
              chainnnn = usdcArbitrumAbi;
              updatetAmt = updatetAmt * 10 ** 6;
            }
          } else if (selectChains == 4) {
            if (chainId != avalancheChainId) {
              toast.dismiss();
              toast.error("Please change network to  Avalanche chain");
              return;
            }
            await getBalance();

            if (selectedCurrency == "1") {
              getTokenAddr = usdtAvalanche;
              chainnnn = usdtAvalancheAbi;
              updatetAmt = updatetAmt * 10 ** 6;
            } else if (selectedCurrency == "2") {
              getTokenAddr = usdcAvalanche;
              chainnnn = usdcAvalancheAbi;
              updatetAmt = updatetAmt * 10 ** 6;
            }
          }

          const res = await checkUser({ address });
          if (res.status) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            const valueInWeii = parseEther(inputamount.toString());
            let balance;

            let contract = new Contract(getTokenAddr, chainnnn, provider);

            if (selectChains == 0) {
              const contract = new Contract(getTokenAddr, chainnnn, provider);

              const checkA = (balance = await contract.allowance(
                address,
                selectAddress
              ));
              if (Number(checkA) / 10 ** 18 < tokenInput) {
                const contract = new Contract(getTokenAddr, chainnnn, provider);

                balance = await contract.approve(
                  selectAddress,
                  "10000000000000000000000000000000000000000000000000000"
                );
                await balance.wait();
                toast.success(
                  "Approved successfully. Please don't refresh the page.fully. Please don't refresh the page."
                );
              }
            } else if (selectChains == 1) {
              if (selectedCurrency == 1 || selectedCurrency == 3) {
                const contract = new Contract(getTokenAddr, chainnnn, provider);

                const checkA = (balance = await contract.allowance(
                  address,
                  selectAddress
                ));

                if (Number(checkA) / 10 ** 8 < tokenInput) {
                  balance = await contract.approve(
                    selectAddress,
                    "10000000000000000000000000000000000000000000000000000"
                  );
                }

                await balance.wait();
                toast.success(
                  " Approved successfully. Please don't refresh the page."
                );
              } else if (selectedCurrency == 2) {
                const valueInWeii = updatetAmt.toString();

                try {
                  const contract = new Contract(
                    getTokenAddr,
                    chainnnn,
                    provider
                  );

                  const checkA = (balance = await contract.allowance(
                    address,
                    selectAddress
                  ));

                  if (Number(checkA) / 10 ** 6 < tokenInput) {
                    balance = await contract.approve(
                      selectAddress,
                      "10000000000000000000000000000000000000000000000000000"
                    );
                  }
                  await balance.wait();
                  toast.success(
                    " Approved successfully. Please don't refresh the page."
                  );
                } catch (error) {
                  console.log(error, "hhhhhhhhhhhh");
                }

                //
              }

              // }
              // approve (0x095ea7b3)
            } else if (
              selectChains == 2 ||
              selectChains == 3 ||
              selectChains == 4
            ) {
              if (selectedCurrency == 1 || selectedCurrency == 2) {
                const contract = new Contract(getTokenAddr, chainnnn, provider);

                const checkA = (balance = await contract.allowance(
                  address,
                  selectAddress
                ));

                if (Number(checkA) / 10 ** 6 < tokenInput) {
                  balance = await contract.approve(
                    selectAddress,
                    "10000000000000000000000000000000000000000000000000000"
                  );
                  await balance.wait();
                  toast.success(
                    " Approved successfully. Please don't refresh the page."
                  );
                }
              }
            }

            contract = new Contract(selectAddress, selectAbi, provider);

            if (selectChains == 0) {
              balance = await contract.buyWithToken(
                inputamount,
                selectedCurrency
              );
            } else {
              balance = await contract.buyWithToken(
                updatetAmt,
                selectedCurrency
              );
            }

            // const balances=  await balance.wait();

            const transactionHash = balance.hash;
            // const reffer_from = localStorage.getItem("referral_code");
            const data = {
              userAddress: address,
              chain: selectChains,
              currency: selectedCurrency,
              amount: tokenInput,
              tokenAmount: ukcInput,
              trans_id: transactionHash,
              reffer_from: reffer_from,
            };
            const token = localStorage.getItem("jwtToken");
            const result = await metaRequestInsert(data, token);

            if (result.status) {
              window.removeEventListener("beforeunload", handleBeforeUnload);
              toast.dismiss("");
              toast.success(result.message);

              setTimeout(() => {
                window.location.reload();
              }, [10000]);
            } else {
              toast.dismiss("");
              toast.error(result.message);
            }
          } else {
            toast.error(res.message);
            localStorage.setItem("chain", selectChains);
            localStorage.setItem("currency", selectedCurrency);
            localStorage.setItem("tokenInput", tokenInput);
            localStorage.setItem("ukcInput", ukcInput);
            localStorage.setItem(
              "selectedCurrencyUserBalance",
              selectedCurrencyUserBalance
            );

            setTimeout(() => {
              navigate("/sign-up");
            }, 2000);
          }
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  wallet");
      }
    } catch (err) {
      console.log(err, "hh");
      if (
        err.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toast.error("Insufficient allowance");
      }
    }
  };

  const busdPrice = async (e) => {
    e.preventDefault();
    var busdAmt = e.target.value;

    settokenInput(busdAmt);
    let res;
    //await checkApproval();
    var getVal = selectedCurrency;

    if (getVal == "0") {
      res = (busdAmt * bnbPrice) / (tokenPrice * 100000000);
    } else {
      var otherTokenPrice = 1;

      if (getVal == "1") {
        otherTokenPrice = btcPrice;
      } else if (getVal == "2") {
        otherTokenPrice = ethPrice;
      }
      res = (busdAmt * otherTokenPrice) / tokenPrice;

      if (selectChains == "1") {
        if (getVal == "2") {
          res = (busdAmt * 1) / tokenPrice;
        }
      }
      if (selectChains == "2" || selectChains == "3" || selectChains == "4") {
        if (getVal == "1" || getVal == "2") {
          res = (busdAmt * 1) / tokenPrice;
        }
      }
    }

    res = res.toFixed(2);

    setukcInput(res);
  };
  const getTotalSupply = async () => {
    try {
      let provider = new JsonRpcProvider(EthRpc);

      let contract = new Contract(tokenAddress, tokenAbi, provider);
      console.log(contract, "call");
      let balance = await contract?.totalSupply();

      balance = Number(balance);

      balance = balance / 10 ** 18;

      setTotalSupply(balance);
    } catch (error) {
      console.error("An error occurred while getting total supply:", error);
    }
  };

  const reverceBusdPrice = async (e) => {
    var tokenAmt = e.target.value;
    let res;
    setukcInput(tokenAmt);
    var getVal = selectedCurrency;

    if (getVal == "0") {
      res = (tokenAmt * 100000000 * tokenPrice) / bnbPrice;
    } else {
      var otherTokenPrice = 1;
      if (getVal == "1") {
        otherTokenPrice = btcPrice;
      } else if (getVal == "2") {
        otherTokenPrice = ethPrice;
      }
      if (selectChains == "1") {
        if (getVal == "2") {
          res = (tokenAmt * 1) / tokenPrice;
        }
      }
      res = (tokenAmt * tokenPrice) / otherTokenPrice;
    }

    res = res.toFixed(8);
    settokenInput(res);
  };

  const getAllPrice = async () => {
    try {
      let provider;
      if (selectChains == 0) {
        provider = new JsonRpcProvider(bscRpc);
      } else if (selectChains == 1) {
        provider = new JsonRpcProvider(EthRpc);
      } else if (selectChains == 2) {
        provider = new JsonRpcProvider(polygonRpc);
      } else if (selectChains == 3) {
        provider = new JsonRpcProvider(arbitrumRpc);
      } else if (selectChains == 4) {
        provider = new JsonRpcProvider(avalancheRpc);
      }

      const contract = new Contract(selectAddress, selectAbi, provider);

      const result = await contract.allPrice();

      let bnbPrice = Number(result[0]);

      let tokenPrice = Number(result[1]);

      let tokenPriceDecimalVal = Number(result[2]);
      let tokenPriceDecimal = Math.pow(10, tokenPriceDecimalVal);
      let price = tokenPrice / tokenPriceDecimal;
      let priceLatest = Number(price)
        .toFixed(tokenPriceDecimalVal)
        .replace(/\.?0+$/, "");

      let wbtcTokenPrice = Number(result[3]);
      let wbtcTokenPriceDecimalVal = Number(result[4]);
      let wbtcTokenPriceDecimal = Math.pow(10, wbtcTokenPriceDecimalVal);
      let wbtcPrice = wbtcTokenPrice / wbtcTokenPriceDecimal;

      let wbtcPriceLatest = Number(wbtcPrice)
        .toFixed(wbtcTokenPriceDecimalVal)
        .replace(/\.?0+$/, "");

      let wethTokenPrice = Number(result[5]);
      let wethTokenPriceDecimalVal = Number(result[6]);
      let wethTokenPriceDecimal = Math.pow(10, wethTokenPriceDecimalVal);
      var wethPrice = wethTokenPrice / wethTokenPriceDecimal;
      let wethPriceLatest = Number(wethPrice)
        .toFixed(wethTokenPriceDecimalVal)
        .replace(/\.?0+$/, "");
      console.log(wethPriceLatest, "wethPriceLatest");

      setbnbPrice(bnbPrice);
      setethPrice(wethPriceLatest);
      setbtcPrice(wbtcPriceLatest);
      settokenPrice(priceLatest);
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    if (
      (isConnected && chainId == binanceChainId) ||
      (isConnected && chainId == ethChainId) ||
      (isConnected && chainId == polygonChainId) ||
      (isConnected && chainId == arbitrumChainId) ||
      (isConnected && chainId == avalancheChainId)
    ) {
      getAllPrice();
      getBalance();
      getTotalSupply();

      if (chainId != binanceChainId) {
      }
    }

    if (!isConnected) {
      localStorage.removeItem("jwtToken");
      setSelectedCurrency("");
      setukcInput("");
      settokenInput("");
      // setuserUkcBalance("");
      setselectedCurrencyUserBalance("");
      $("#currency_id").val("");
      $("#table_body").html();
    }
  }, [tokenInput, address, selectChains, selectedCurrency]);

  useEffect(() => {
    getAllPrice();
  }, []);

  const authUserData = async () => {
    if (address && address) {
      const data = await authUser({ address });
      localStorage.setItem("jwtToken", data.token);
    }
  };
  useEffect(() => {
    authUserData();
  }, [address]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const localAddress = localStorage.getItem("address");

      if (isConnected && localAddress !== address) {
        localStorage.setItem("address", address);

        window.location.reload();
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isConnected, address]);

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const homeRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const faqRef = useRef(null);
  const roadmapRef = useRef(null);
  const aboutUsRef = useRef(null);
  const buynowRef = useRef(null);
  const peopleUsRef = useRef(null);
  const businessRef = useRef(null);
  const signupRef = useRef(null);
  const howtobuyRef = useRef(null);
  const referralEarnRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    if (params.walletAddress) {
      getUSerReferralCode(params.walletAddress);
    } else {
      setLoaderStatus(false);
      setMainLoaderStatus(false);
    }
  }, [params.walletAddress]);
  useEffect(() => {
    if (location.hash || homeRef.current) {
      const targetRef =
        location.hash === "#about-us"
          ? aboutUsRef
          : location.hash === "#tokenomics"
          ? tokenomicsRef
          : location.hash === "#faq"
          ? faqRef
          : location.hash === "#roadmap"
          ? roadmapRef
          : location.hash === "#buy-now"
          ? buynowRef
          : location.hash === "#people"
          ? peopleUsRef
          : location.hash === "#business"
          ? businessRef
          : location.hash === "#sign-up"
          ? signupRef
          : location.hash === "/#how-to-buy"
          ? howtobuyRef
          : location.hash === "#referral-earn"
          ? referralEarnRef
          : location.hash === "/"
          ? homeRef
          : null;

      if (targetRef && !loaderStatus) {
        targetRef.current.scrollIntoView({ behavior: "auto", block: "start" });
      }
    } else {
      if (!loaderStatus) {
        const targetRef = homeRef;
        // console.log(targetRef,"targetRef")
        // targetRef?.current?.scrollIntoView({ behavior: "auto", block: "start" });
        window.scrollTo(0, 0);
      }
    }
  }, [location.hash, loaderStatus]);

  const scrollToHome = () => {
    if (homeRef.current) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      // homeRef.current.scrollIntoView({ top: 0,behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  // console.log(homeRef.current,"homeRef.curren");
  const scrollToAbout = () => {
    if (aboutUsRef.current) {
      window.scrollTo({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToTokenomics = () => {
    if (tokenomicsRef.current) {
      // tokenomicsRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToFaq = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollTobuynow = () => {
    if (buynowRef.current) {
      // window.scrollTo({ behavior: "smooth", top: 2600 });
      window.scrollTo({ behavior: "smooth" });
      // buynowRef.current.scrollIntoView({ behavior: "smooth",top: 0 });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToRoadmap = () => {
    if (roadmapRef.current) {
      // roadmapRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToPeople = () => {
    if (peopleUsRef.current) {
      // roadmapRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth", top: 2500 });
      setSelectTeb("0");
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToBusiness = () => {
    if (businessRef.current) {
      // roadmapRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth" });
      setSelectTeb("1");
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollTosignupRef = () => {
    if (signupRef.current) {
      // roadmapRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth" });
      // setSelectTeb("1");
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToHowToBuyRef = () => {
    if (howtobuyRef.current) {
      howtobuyRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToReferralEarn = () => {
    if (referralEarnRef.current) {
      window.scrollTo({ behavior: "smooth" });
      // referralEarnRef.current.scrollIntoView({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  // console.log(location.hash,"location",homeRef.current);

  useEffect(() => {
    if (location.hash === "#people") {
      selectTebs("0");
    }
  }, [selectTeb, location.hash]);

  // =========== slider ============

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const handleOnChanges = (value, country) => {
    setMobile_number(value);

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setMobile_numberErr("Mobile Number is required");
    } else {
      setMobile_numberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };
  const registerHandler = async () => {
    if (!name) {
      setNameErr("Name is required");
      return;
    }
    if (!email) {
      setEmailErr("This field is required");
      return;
    }
    if (!Emailpattern.test(email)) {
      setEmailErr("Please enter valid email");
      return;
    }
    if (!number) {
      setMobile_numberErr("This field is required");
      return;
    }

    const reffer_from = localStorage.getItem("referral_code");
    let data = {
      name,
      email,
      mobile_number: number,
      country_code,
      reffer_from,
    };

    const result = await registerUser(data);
    if (result.status) {
      // let token = result.token;
      // localStorage.setItem("jwtToken", token);

      setTimeout(() => {
        navigate("/#buy-now", { replace: true });
        setTimeout(() => {
          window.scrollTo(0, window.scrollY);
        }, 100);
      }, 2000);

      toast.success(result.message);
      setName("");
      setCountry_code(" ");
      setEmail("");
      setMobile_number("");
    } else {
      toast.error(result.message);
    }
  };
  const handleChange = (e) => {
    const err = "This field is required";
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("Name is required");
      } else {
        setNameErr("");
      }
    }
    if (name == "email") {
      setEmail(value);

      if (value == "") {
        setEmailErr(err);
      } else {
        if (!Emailpattern.test(value)) {
          setEmailErr("Please enter valid email");
        } else {
          setEmailErr("");
        }
      }
    }
    if (name == "mobile_number") {
      setMobile_number(value);

      if (value === "") {
        setMobile_numberErr("Mobile Number is required");
      } else {
        setMobile_numberErr("");
      }
    }
  };

  // useEffect(() => {
  //   if (!location.hash||location.hash==""||location.hash=="undefined") {
  //     console.log("callll");
  //     window.scrollTo(0, 0);
  //   }
  // }, []);
  useEffect(() => {
    if (!window.location.hash) {
      console.log("callll");
      window.scrollTo(0, 0);
    }
  }, [location]);
  // useEffect(() => {
  //   console.log("call52222");
  //       window.scrollTo({ top: 0, behavior: "smooth" });

  //     }, []);
  // =============== for chains ===============

  const chainImages = [
    "/img/bnb-white.png",
    "/img/eth-white.png",
    "/img/polygon-white.png",
    "/img/arbitrum-white.png",
    "/img/avalanche-white.png",
  ];

  const handleSelectChain = (index) => {
    setSelectedChain(index);
  };
  const chainButtons = [
    { index: "0", imgSrc: "/img/bnb-white.png", alt: "bnb" },
    { index: "1", imgSrc: "/img/eth-white.png", alt: "usdt" },
    { index: "2", imgSrc: "/img/polygon-white.png", alt: "polygon" },
    // { index: "3", imgSrc: "/img/arbitrum-white.png", alt: "arbitrum" },
    { index: "4", imgSrc: "/img/avalanche-white.png", alt: "avalanche" },
  ];
  // =================chains=====================
  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + referral_code);
      setCopyText("Copied");
    }
  };

  const getUSerReferralCode = async (referral) => {
    let data = {
      referral_code: referral,
    };

    const result = await getUSerReferral(data);
    if (result.status) {
      localStorage.setItem("referral_code", params.walletAddress);
      setLoaderStatus(false);
      setMainLoaderStatus(false);
    } else {
      setLoaderStatus(true);
      setMainLoaderStatus(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (address) {
        try {
          const result = await generateReferral({ wallet_address: address });

          setReferral_code(result?.data);
        } catch (error) {
          console.error("Error fetching referral:", error);
        }
      }
    };

    fetchData();
  }, [address]);

  // ============= for range ===============

  const getValueCategory = (value) => {
    if (value <= 2499) {
      return 1;
    } else if (value <= 4999) {
      return 2;
    } else if (value <= 9999) {
      return 3;
    } else if (value <= 19999) {
      return 4;
    } else if (value <= 49999) {
      return 5;
    } else {
      return 6;
    }
  };

  const getInvestmentAmt = (value) => {
    if (value <= 2499) {
      return "$1,000-$2,499 +Bonus 5%";
    } else if (value <= 4999) {
      return "$2,500-$4,999+ Bonus 10%";
    } else if (value <= 9999) {
      return "$5,000-$9,999+ Bonus 15%";
    } else if (value <= 19999) {
      return "$10,000-$19,999+ Bonus 20%";
    } else if (value <= 49999) {
      return "$20,000-$49,999+ Bonus 30%";
    } else {
      return "$50000+ Bonus 50%";
    }
  };
  const tierDetails = [
    {
      category: 1,
      items: [
        "A 1% cashback at the gas station",
        "1 Entry in our ‘Free Fuel for 1 Year’ Giveaway",
        "Dedicated Account Manager",
      ],
    },
    {
      category: 2,
      items: [
        "A 1.5% cashback at the gas station",
        "2 Entries in our ‘Free Fuel for 1 Year’ Giveaway",
        "Dedicated Account Manager",
      ],
    },
    {
      category: 3,
      items: [
        "A 2% cashback at the gas station",
        "5 Entries in our ‘Free Fuel for 1 Year’ Giveaway",
        "Dedicated Account Manager",
      ],
    },
    {
      category: 4,
      items: [
        "Gold Crudo Loyalty Card",
        "An extra 2.5% cashback at the gas station",
        "10 Entries in our ‘Free Fuel for 1 Year’ Giveaway",
        "Early access to Crudo for people beta testing",
        "1 Year of free access to the Crudo platform after launch (including all tools)",
      ],
    },
    {
      category: 5,
      items: [
        "Platinum Crudo Loyalty Card",
        "An extra 5% cashback at the gas station",
        "25 Entries in our ‘Free Fuel for 1 Year’ Giveaway",
        "Early access to Crudo for People beta testing",
        "Early access to Crudo for Business beta testing",
        "3 Years of free access to the Crudo platform after launch (including all tools)",
      ],
    },
    {
      category: 6,
      items: [
        "Diamond Crudo Loyalty Card",
        "An extra 10% cashback at the gas station",
        "50 Entries in our ‘Free Fuel for 1 Year’ Giveaway",
        "Early access to Crudo for People beta testing",
        "Early access to Crudo for Business beta testing",
        "Early access to Crudo App",
        "5 Years of free access to the Crudo platform after launch (including all tools)",
        "VIP access to Crudo live events in 2025",
      ],
    },
  ];

  const category = getValueCategory(value);
  const tier = tierDetails.find((tier) => tier.category === category);

  useEffect(() => {
    let result;
    const fetchData = async () => {
      try {
        result = await getStageData();

        setStageRecord(result?.data);
      } catch (error) {
        console.error("Error fetching referral:", error);
      }
    };

    fetchData();
    const fetchAndCalculate = async () => {
      const resp = await getTotalUsdt();
      if (resp.status) {
        const totalusdt = parseInt(resp?.usdtAmt);

        const totalToken = parseInt(resp?.tokenAmt);
        setTokenLivePrice(resp?.tokenprice);

        const totalUsdts = totalusdt + 211186;
        console.log(totalUsdts,"totalUsdts")
        // - 14883-52617 -469444
        const stage =
          totalUsdts <= 320000
            ? 1
            : totalUsdts <= 680000
            ? 2
            : totalUsdts <= 1080000
            ? 3
            : totalUsdts <= 1680000
            ? 4
            : totalUsdts <= 2680000
            ? 5
            : totalUsdts <= 4150000
            ? 6
            : totalUsdts <= 6040000
            ? 7
            : totalUsdts <= 8350000
            ? 8
            : totalUsdts <= 11080000
            ? 9
            : totalUsdts <= 14230000
            ? 10
            : 11;

        setTotalUsdt(totalusdt);

        setTotalToken(totalToken);
        setStages(stage);

        if (result?.data && stage) {
          const matchingItem = result?.data.find(
            (item) => item?.stage == stage
          );
          console.log(matchingItem.total_amoun, "total_amoun");
          if (matchingItem) {
            setTotalAmt(matchingItem.total_amount);

            setTotalTokenAmt(matchingItem.token);
            let numericValue = parseInt(
              matchingItem.total_amount?.replace(/,/g, ""),
              10
            );
            setNumericVal(numericValue);
            let numericTokenValue = parseInt(
              matchingItem.token?.replace(/,/g, ""),
              10
            );
            setNumericTokenVal(numericTokenValue);
          }
        }
      }
    };

    fetchAndCalculate();
  }, []);

  const links = [
    {
      href: "https://es.cointelegraph.com/press-releases/middle-eastern-blockchain-startup-set-to-revolutionise-the-oil-industry",
      src: "/img/cointelegraph.png",
      alt: "cointelegraph",
    },
    {
      href: "https://coingape.com/best-crypto-presales/#2",
      src: "/img/coingape.png",
      alt: "coingape",
    },
    {
      href: "https://finance.yahoo.com/news/ethereum-eth-etf-approved-crudo-143600831.html?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAA8ul5N0zcTSiTnQkKGeV8guMTsyM1C6RWwgj8jLnj79mkjy1V5pM7r0My4_wudSz-pgiS813KNWHEqkoFtSF3DsHyOPCfyvqScAuXorbCGkyj9aWdd8yl9AAcXC-T0uMCvg_ysGYsszu450HrWRHBO19VJATOe271ZRig_y7zcc&guccounter=2",
      src: "/img/yahoo_news_logo2.png",
      alt: "yahoo_news_logo",
    },
    {
      href: "https://www.analyticsinsight.net/cryptocurrency-analytics-insight/bitcoin-etf-issuer-vaneck-files-for-solana-etf-while-crudo-protocol-could-shape-the-future-of-energy-investments",
      src: "/img/analyticsinsight.png",
      alt: "analyticsinsight",
    },
    {
      href: "https://coinpedia.org/sponsored/crudo-token-next-big-thing-in-oil-market-innovation-presale-alert/",
      src: "/img/coinpedia.png",
      alt: "coinpedia",
    },
    {
      href: "https://www.coinspeaker.com/participate-crudo-token-presale-transform-investment-strategy/",
      src: "/img/coinspeaker.png",
      alt: "coinspeaker",
    },
    {
      href: "https://crypto.news/crudo-protocols-crudo-token-presale-in-full-swing-first-stage-nearly-sold-out/",
      src: "/img/crypto.news.png",
      alt: "crypto.news",
    },
    {
      href: "https://www.cryptopolitan.com/best-crypto-presales-to-invest-in-now-the-ultimate-presale-guide-of-2024/",
      src: "/img/cryptopolitan.png",
      alt: "cryptopolitan",
    },
    {
      href: "https://coincodex.com/article/43878/crudo-token-set-to-launch-at-020-why-early-investors-are-set-for-massive-gains/",
      src: "/img/coincodex.png",
      alt: "coincodex",
    },
    {
      href: "https://bitcoinist.com/crudo-token-to-launch-at-0-20-why-this-is-a-game-changer-for-investors/",
      src: "/img/bitcoinist.png",
      alt: "bitcoinist",
    },
  ];

  // ============= timer ==================/
  const calculateTimeLeft = () => {
    const targetDate = new Date(Date.UTC(2025, 2,26, 8, 0, 0)); // 23 August 10:00 UTC
    const currentDate = new Date();

    const difference = targetDate - currentDate;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  // =============== timer
  // ============= for range ===============
  // ================ slider =================
  // const settingss = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,    autoplay: true,
  //         autoplaySpeed: 5000,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 2,    autoplay: true,
  //         autoplaySpeed: 5000,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,    autoplay: true,
  //         autoplaySpeed: 5000,
  //       },
  //     },
  //   ],
  // };
  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true, // Add this property
    centerPadding: "0", // Optional: Adjust padding if needed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };

  // ==============slider================

  return (
    <>
      {loaderStatus ? (
        <h2> {mainloaderStatus ? "Invalid URL" : ""}</h2>
      ) : (
        <>
          <section class="bg1">
            <Navbar
              setSelectTeb={setSelectTeb}
              selectTebs={selectTebs}
              scrollToHome={scrollToHome}
              scrollToAbout={scrollToAbout}
              scrollToTokenomics={scrollToTokenomics}
              scrollToFaq={scrollToFaq}
              scrollToRoadmap={scrollToRoadmap}
              scrollTobuynow={scrollTobuynow}
              scrollToPeople={scrollToPeople}
              scrollToBusiness={scrollToBusiness}
              scrollToHowToBuyRef={scrollToHowToBuyRef}
              scrollToReferralEarn={scrollToReferralEarn}
            />
            <Header scrollTobuynow={scrollTobuynow} />
            <div id="scroll-to-top">
              <i class="bi bi-chevron-up fa-fw"></i>
            </div>

            <section
              className="ex_box p70 pb-0"
              onClick={scrollTobuynow}
              id="buy-now"
              // data-scroll-index={3}
              ref={buynowRef}
            >
              <div className="container  position-relative">
                <div className="row">
                  <div className=" token_info_man  col-md-6 mb-4 mb-md-0">
                    <div className="ex_box_in position-relative box mr-md-4">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="home_profile_text">
                            <w3m-button balance="hide" />
                          </div>
                        </div>
                        <div className="home_profile_right text-right ml-auto">
                          <img src="/img/coin-white.png" alt="app-coins " />
                        </div>
                      </div>
                      <div className="h6 mt-4 stage_box fw600">
                        <div className="d-flex align-items-center mt-2">
                          <span className="ttu ">Crudo Protocol Presale</span>
                          <span className="color1 ttu  ml-auto ">
                            Stage {stages} of 5
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <span className="ttu ">USDT Raised: </span>
                          <span className=" ml-2 mb-0">
                            $ {totalUsdt ? formattedTotalUsdt : "0"}
                            {/* $ 681,108  */}
                            {/* <span className="light_text ml-1">
                          /{totalAmt ? totalAmt : 0}
                        </span> */}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-2 ">
                          <span className="ttu ">
                            {" "}
                            Tokens Sold in Stage {stages}:{" "}
                          </span>
                          <span className=" ml-2 mb-0">
                            {totalToken ? formattedTotalTokenStage2 : "0"}
                            {/* 349,134 */}
                            <span className="light_text ml-1">
                              /{totalAmt ? "40,000,000" : 0}
                            </span>
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-2 ">
                          <span className="ttu ">Token Price: </span>
                          <span className=" ml-2 mb-0">
                            $ {tokenLivePrice ? tokenLivePrice : "0"}
                          </span>
                        </div>
                      </div>
                      {/* <div className="d-md-flex align-items-center mt-3">
                    <span className="ttu ">0</span>
                    <span className="ml-auto ">80,000,000</span>
                  </div> */}
                      <div className="progress  mb-1">
                        <div
                          className="progress-bar"
                          style={{
                            width: totalToken
                              ? `${parseInt(
                                  (((totalToken + 26250000) * 100) /
                                    Number(200000000)) %
                                    100
                                )}%`
                              : "0%",
                          }}
                        />
                        <span className="pp">
                          {totalToken
                            ? parseInt(
                                ((totalToken + 26250000) * 100) /
                                  Number(200000000)
                              )
                            : 0}
                          %
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="ttu ">0</span>
                        <span className="ml-auto ">200,000,000</span>
                      </div>
                      {isConnected ? (
                        <div className="d-md-flex  align-items-center mt-2 h6 fw600">
                          <span className="ttu">Balance: </span>
                          <span className="ml-auto">
                            {selectedCurrencyUserBalance
                              ? selectedCurrencyUserBalance
                              : "0"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="d-md-flex align-items-center mt-2 h6 fw600">
                        <span className="ttu ">Total Token Sold: </span>
                        <span className="ml-auto ">
                          {" "}
                          {totalToken ? formattedTotalToken : "0"}
                        </span>
                      </div>
                      <div className="d-md-flex align-items-center h6 fw600">
                        <span className="ttu ">Listing Price: </span>
                        <span className="ml-auto ">$ 0.20</span>
                      </div>
                      {isConnected ? (
                        <div className="d-md-flex align-items-center h6 fw600">
                          <span className="ttu ">Referral Bonus Token: </span>
                          <span className="ml-auto ">
                            {referralBonus ? referralBonus : "0"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <h6 className="text-center mb-1 mt-4"></h6>

                      <div className=" mt-2">
                        <div className="tab_btn d-flex mb-3">
                          {chainButtons.map(({ index, imgSrc, alt }) => (
                            <button
                              key={index}
                              onClick={() => selectChain(index)}
                              className={selectChains === index ? "active" : ""}
                            >
                              <img src={imgSrc} alt={alt} />
                            </button>
                          ))}
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <input
                            onKeyPress={validNumber}
                            step="0.1"
                            onChange={busdPrice}
                            min="0"
                            placeholder="0"
                            value={tokenInput}
                            id="busdAmtIdo"
                            className="input_item"
                          />
                          <img
                            src={chainImages[selectChains]}
                            alt="Chain Logo"
                            className="in_icon position-absolute"
                            onClick={() => handleSelectChain(selectChains)}
                          />
                          <select
                            class=" select_dark"
                            onChange={(e) => selectCurrency(e)}
                            id="currency_id"
                            name="currency_id"
                            value={selectedCurrency}
                          >
                            <option value="">Select Currency</option>
                            {selectChains == 0 ? (
                              <>
                                <option value="0">BNB</option>
                                <option value="1">WBTC (BEP20)</option>
                                <option value="2">WETH (BEP20)</option>
                                <option value="3">USDT (BEP20)</option>
                                <option value="4">USDC (BEP20)</option>
                              </>
                            ) : selectChains == 1 ? (
                              <>
                                <option value="0">ETH</option>
                                <option value="1">WBTC (ERC20)</option>
                                <option value="2">USDT (ERC20)</option>
                                <option value="3">USDC (ERC20)</option>
                              </>
                            ) : selectChains == 2 ? (
                              <>
                                <option value="0">MATIC</option>
                                <option value="1">USDT (polygon)</option>
                                <option value="2">USDC (polygon)</option>
                              </>
                            ) : selectChains == 3 ? (
                              <>
                                <option value="0">ARB</option>
                                <option value="1">USDT (ARB)</option>
                                <option value="2">USDC (ARB)</option>
                              </>
                            ) : selectChains == 4 ? (
                              <>
                                <option value="0">AVAX</option>
                                <option value="1">USDT (AVAX)</option>
                                <option value="2">USDC (AVAX)</option>
                              </>
                            ) : null}
                          </select>
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <input
                            placeholder="Max CRUDO Received"
                            class="reverceBusdPrice"
                            onChange={reverceBusdPrice}
                            min="0"
                            value={ukcInput}
                            disabled
                            id="tokenAmtIdo2"
                            className="input_item"
                          />
                          <img
                            src="/img/coin-input.png"
                            alt="coin "
                            className="in_icon position-absolute"
                          />
                        </div>
                        <div className="pt-2">
                          <button className="btn  w100 ttu mb-3" onClick={buy}>
                            {buyBtnText}
                          </button>
                          <a
                            href="/transaction-details"
                            className="btn btn_border2 w100 mt-2"
                            id="btn-connect-wallet"
                          >
                            Transaction Dashboard
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" token_info_man  col-md-6">
                    <div className="  ml-md-4">
                      <h3 className="hadding mb-3">
                        Join the Oilconomy with <span>CRUDO Token</span> Presale
                      </h3>
                      <p className="f22">
                        Crudo Protocol is the first Web3 ecosystem for people
                        and businesses in the Oil and Gas industry.
                      </p>

                      <a
                        className="btn w100 mt-4"
                        target="_blank"
                        href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v2"
                      >
                        Read the Whitepaper
                      </a>

                      <a
                        className="btn w100 btn_border mt-4 "
                        onClick={scrollToBusiness}
                        href="/#business"
                      >
                        Crudo for Business
                      </a>
                      <a
                        className="btn w100 btn_border mt-4 "
                        onClick={scrollToPeople}
                        href="/#people"
                      >
                        {" "}
                        Crudo for People{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="p70 pb-0">
              <div className="container  position-relative">
                <div className="box overflow_initial ">
                  <div className="row ">
                    <div className="col-md-6 mb-4 mb-md-0">
                      <div className="">
                        <h5 className="mb-3">
                          Investment Amount <br />
                          Starting from
                          <span className=" h4"> $1000</span>
                        </h5>
                        <p className="">
                          Unlock exceptional rewards and receive more $CRUDO
                          tokens by becoming a member of the Crudo Members Hub!
                          Starting with an investment of just $1000, you can
                          enjoy unique benefits available only during our
                          presale
                        </p>
                      </div>
                      <div className=" mt-md-5 mt-3 pt-md-4 hmd">
                        <div className="d-flex align-itms-center">
                          <span className="">
                            How much do you want to spend?
                          </span>
                          <h5 className="ml-auto mb-0">$ {value}</h5>
                        </div>
                        <div>
                          <div className="range-slider single-thumb mt-2">
                            <input
                              type="range"
                              className="myinput"
                              min="1000"
                              max="50000"
                              value={value}
                              onChange={handleChangeForRange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="box_right">
                        <div className="row  ">
                          <div className="col-md-6 pr-md-0">
                            <div className="d-flex align-items-center">
                              <img
                                className="black-coin"
                                src="/img/black-coin.png"
                                alt="logo coin"
                              />
                              <h3 className=" pl-2 mb-0">
                                Tier {getValueCategory(value)}
                              </h3>
                            </div>
                            <div className="mt-4">
                              {tier && (
                                <ul className="tier_list">
                                  {tier.items.map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <img
                              className="tier_img position-absolute"
                              src={`/img/tier${getValueCategory(value)}.png`}
                              alt="tier"
                            />

                            <div className="position-absolute tier_text text-center">
                              <b className="mb-0 ">Investment Amount</b>
                              <h6 className="mb-0 mt-1">
                                {getInvestmentAmt(value)}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="pt-4  ">
                          <a
                            type="button"
                            href="/#buy-now"
                            className=" btn w100"
                            onClick={scrollTobuynow}
                          >
                            <span className="">BUY CRUDO</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="p70   pb-md-5 video_section text-center">
              <div className="container  position-relative">
                <h2 className="hadding text-center mb-2">
                  KOLs Talking <span className="color1">About Crudo </span>
                </h2>
                <h5 className="text-center mb-4">
                  Over 500,000 views on YouTube{" "}
                </h5>
                <div className="row">
                  <div className="col-md-12 m-auto">
                    <Slider {...settingss}>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=xUYXCpksFmM&t=13s"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub5.jpg"
                            alt="cruo Youtub4"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=Dad4-LU_M3E"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub1.jpg"
                            alt="cruo Youtub"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=q0DVFiFw73g&t=15s"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub3.jpg"
                            alt="cruo Youtub2"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=drd7836WkYU"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub4.jpg"
                            alt="cruo Youtub3"
                            className="w100"
                          />
                        </a>
                      </div>

                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=zCZbEyDY060&t=354s"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub6.jpg"
                            alt="cruo Youtub5"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=8lZ8zLTSCUQ&t=15s"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub7.jpg"
                            alt="cruo Youtub"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=e8WGR4g4mYA"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub2.jpg"
                            alt="cruo Youtub"
                            className="w100"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/watch?v=l4yRwemc7iU"
                          target="_blank"
                        >
                          <img
                            src="/img/youtub8.jpg"
                            alt="cruo Youtub"
                            className="w100"
                          />
                        </a>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section className="p70  pb-0 pt-md-4 logo_top text-center">
              <div className="container  position-relative">
                <h2 className="hadding text-center">
                  Featured <span className="color1">On</span>
                </h2>

                <div className="row align-items-center">
                  {links.map((link, index) => (
                    <LinkItem key={index} {...link} />
                  ))}
                </div>
              </div>
            </section>

            <section
              className="ex_box p70 pb-0 "
              id="about-us"
              ref={aboutUsRef}
            >
              <div className="container  position-relative">
                <div className="box p-lg-4">
                  <div className="p-0 p-md-5">
                    <h2 className="hadding text-center">
                      Seamless Entry, <span className="color1">Limitless</span>
                      <br />
                      Potential
                    </h2>
                    <p className="f24 text-center mt-md-5 mt-3 pl-md-4 pr-md-4">
                      At CRUDO, we're passionate about democratizing access to
                      the lucrative realm of crude oil investment. We're
                      breaking barriers by providing a user-friendly platform
                      with a low entry threshold.
                    </p>
                    <div className="row  ">
                      <div className="col-md-4 mt-4">
                        <div className="lrl_box">
                          <img src="/img/lp.png" alt="LOYALTY program" />
                          <h4 className="ttu mt-4 color1">LOYALTY Program</h4>
                          <p>
                            Introducing a loyalty program that rewards users
                            with CRUDO tokens for frequent gas purchases at
                            affiliated petrol stations.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4  mt-4">
                        <div className="lrl_box">
                          <img src="/img/rs.png" alt="Reward system" />
                          <h4 className="ttu mt-4 color1">Reward system</h4>
                          <p>
                            Develop a system where users can earn bonus tokens
                            for consistent purchases, referrals, or engaging
                            with specific promotions.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4  mt-4">
                        <div className="lrl_box">
                          <img src="/img/lo.png" alt="Loyalty offerings" />
                          <h4 className="ttu mt-4 color1">Loyalty offerings</h4>
                          <p>
                            Enable gas stations to customize their loyalty
                            offerings, such as discounts, freebies, or exclusive
                            deals for customers using CRUDO tokens for payments.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6  mb-4">
                      <div className="three_box three_box1">
                        <span className="bgg1" />
                        <h4 className=" mt-4  mt-md-5 mb-3">CRUDO Token</h4>
                        <p>
                          Crudo Protocol incentivizes consumer engagement,
                          drives traffic to affiliated stations, and enhances
                          the utility and adoption of CRUDO tokens within the
                          ecosystem.
                        </p>
                        <a
                          className="btn btn2 ttu mt-4"
                          href="/#buy-now"
                          onClick={scrollTobuynow}
                        >
                          Buy Now
                        </a>
                        <br />
                        <img src="/img/coin.png" alt="tokenomics" className />
                      </div>
                    </div>
                    <div className="col-md-6  mb-4">
                      <div className="three_box three_box2">
                        <div className="row align-items-center">
                          <div className="col-md-5  text-center">
                            <img src="/img/lcpu.png" alt="lcpu" className />
                          </div>
                          <div className="col-md-7  ">
                            <h4 className=" ">
                              Live commodities <br />
                              Price updates
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="three_box three_box3 mt-4 pt-0 pb-0">
                        <div className="row align-items-center">
                          <div className="col-4">
                            <h4 className=" color1">
                              Ship AIS
                              <br /> tracking
                            </h4>
                          </div>
                          <div className="col-8">
                            <div className="d-flex">
                              <marquee className="marq" direction="down">
                                <div className="geek1">Vessel</div>
                                <div className="geek1">Real-time</div>
                                <div className="geek1">Tracking</div>
                              </marquee>
                              <marquee className="marq" direction="up">
                                <div className="geek1">Refined</div>
                                <div className="geek1">Raw</div>
                                <div className="geek1">Static</div>
                              </marquee>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  mt-4 ">
                    <div className="col-md-3  mb-4">
                      <div className="three_box three_box4">
                        <h5 className="color1">
                          Due Diligence
                          <br /> Reports
                        </h5>
                      </div>
                      <div className="three_box  text-center mt-4">
                        <h5 className>
                          Marketplace for Enterprises in the Trading Industry
                        </h5>
                        <a className="btn btn3 ttu mt-2" href="#">
                          GET STARTED
                        </a>
                      </div>
                    </div>
                    <div className="col-md-9  mb-4">
                      <div className="three_box three_box1 three_box6 d-flex">
                        <div className="row m-auto w100">
                          <div className="col-md-5  ">
                            <h4 className>Blockchain Powered Efficiency</h4>
                          </div>
                          <div className="col-md-5  ml-auto text-md-right">
                            <p>
                              Powered by blockchain technology, CRUDO ensures
                              transparent, secure, and instantaneous
                              transactions for all our users. By integrating
                              blockchain into our platform, we've streamlined
                              the investment process, eliminated hidden fees,
                              and provided users with unparalleled autonomy over
                              their investments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <SwiperSlider />

            <section
              className="ex_box p70 pb-0"
              id="referral-earn"
              data-scroll-index={3}
              ref={referralEarnRef}
            >
              <div className="container  position-relative">
                <div className="row  ">
                  <div className="col-md-10 m-auto">
                    <h3 className="hadding text-center mb-md-4">
                      Refer to <span>Earn</span>
                    </h3>
                    <p className="text-center mb-4  mb-md-5">
                      Join our referral program and share the excitement of
                      Crudo Protocol with your friends, family and community.
                      For every referral who makes a purchase on our official
                      website, you'll receive a 10% bonus in CRUDO tokens,
                      visible on your user dashboard and claimable upon launch.
                    </p>
                  </div>
                </div>
                <div class="row pz1  ">
                  <div class="col-md-6">
                    <div class="three_box h100 three_box7 ">
                      <span class="bgg1"></span>
                      <div className="home_profile_text">
                        <w3m-button balance="hide" />
                      </div>
                      <p className="pb-1 mt-4 h5  position-relative">
                        Earn more 
                        <span className=""> $CRUDO </span>by referring your
                        <br /> friends and community
                      </p>

                      <div class="refrail_link mt-3 mt-md-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={
                            address
                              ? `${copyUrl}${referral_code}`
                              : " Connect Wallet for Referral Link"
                          }
                        />
                        <button
                          className="btn btn2 mt-4"
                          onClick={() => {
                            copyToClipboard(address);
                          }}
                        >
                          <i className="bi bi-copy"></i> {copyText}
                        </button>
                      </div>
                      <div class="text-right">
                        <img
                          src="/img/frint-icon.png"
                          alt="icon"
                          className="frint-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="three_box h100">
                      <p className="pb-1 mt-2 h5  position-relative">
                        The top five users who refer the most will have a chance
                        to win an extra
                      </p>
                      <p className="h3 color2 fw600 mb-md-4">15% in USDT</p>
                      <div className="box8">
                        <ul>
                          <li>
                            <span className="fw600">First Place: </span>
                            <span className="color2">15% </span>bonus in USDT*
                          </li>
                          <li>
                            <span className="fw600">Second Place: </span>
                            <span className="color2">12% </span>bonus in USDT*
                          </li>
                          <li>
                            <span className="fw600">Third Place: </span>
                            <span className="color2">10% </span>bonus in USDT*
                          </li>
                          <li>
                            <span className="fw600">Fourth Place: </span>
                            <span className="color2">8% </span>bonus in USDT*
                          </li>
                          <li>
                            <span className="fw600">Fifth Place: </span>
                            <span className="color2">5% </span>bonus in USDT*
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  mt-4">
                  <div className="col-md-10 m-auto">
                    <p className="text-center">
                      To participate, simply connect your wallet to our website
                      through the buy widget and generate a unique referral link
                      from the Referral widget. Share the link with your network
                      and watch your rewards grow!
                    </p>
                  </div>
                  <div className="col-md-5 m-auto ">
                    <div className="box d-flex align-items-center timer">
                      <small className="ttu ">
                        Until <br />
                        The end
                      </small>
                      <h4 className="m-auto">{timeLeft?.days} Days</h4>
                      <h1 className="mb-0 ggg">
                        {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                      </h1>
                    </div>
                  </div>
                </div>

                <h3 className="hadding text-center mb-md-4 mt-md-5 mt-4 pt-md-3">
                  <span>Monthly Referral Leaderboard</span>
                </h3>

                <div className="">
                  <table className="table teferral_table">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Wallet Address</th>
                        {/* <th>Date</th> */}
                        <th>No of Referrals</th>
                        <th>Total Referral</th>
                        <th>Prize</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {topReferralData?.map((item, index) => {
                       
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.wallet_address}</td>

                            <td>{item?.no_of_referral}</td>
                            <td>${item?.total_referral_amt}</td>

                            <td>USDT {item?.prize}</td>
                          </tr>
                        );
                      })} */}
                      {topReferralData?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.wallet_address}</td>
                          <td>{item?.no_of_referral}</td>
                          <td>${item?.total_referral_amt}</td>
                          <td>USDT {Number(item?.prize).toFixed(2)}</td>
                        </tr>
                      ))}

                      {Array.from({
                        length: 10 - (topReferralData?.length || 0),
                      }).map((_, index) => (
                        <tr key={topReferralData?.length + index}>
                          <td>{topReferralData?.length + index + 1}</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      ))}

                      {/* <tr>
        <td>2nd</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>3rd</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>4th</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>5th</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>6th</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>7th</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr>
      <tr>
        <td>8th</td>
        <td>0xcc341jskfsk3134.....r1c</td>
        <td>14/07/24</td>
        <td>8</td>
        <td>$81,435</td>
        <td>USDT 42.31</td>
      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="ex_box p70 pb-md-0">
              <div className="container  position-relative">
                <div className="row mt-4 align-items-center">
                  <div className="col-md-7">
                    <h3 className="hadding">
                      Join the Fastest growing Global <span>Phenomena</span>
                    </h3>
                    <div className="row pt-md-4  ">
                      <div className="col-6 mt-4">
                        <div className="join_box">
                          <h4 className="color1"> $2T</h4>
                          <p>The size of the Crude Oil market in 2023</p>
                        </div>
                      </div>
                      <div className="col-6 mt-4">
                        <div className="join_box">
                          <h4 className="color1">30B</h4>
                          <p>barrels of Crude Oil produced every year</p>
                        </div>
                      </div>
                      <div className="col-6 mt-4">
                        <div className="join_box">
                          <h4 className="color1">6.5% </h4>
                          <p>
                            Compound Annual Growth Rate (CAGR) of the fuel
                            station market worldwide
                          </p>
                        </div>
                      </div>
                      <div className="col-6 mt-4">
                        <div className="join_box">
                          <h4 className="color1"> 25% </h4>
                          <p>
                            CAGR expected from 2024 to 2030 for the fuel station
                            market
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="   col-md-5">
                    <img
                      src="/img/groing_global.png"
                      alt="coin "
                      className="img-fluid mw115 "
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className=" p70">
              <div className="container">
                <h4 className="hadding text-center mb-3 mo_block">Ecosystem</h4>
                <div className="row">
                  <div className="col-md-8 ml-auto ">
                    <div className="box p-md-5 h100">
                      <div className="text-center mb-md-5 mb-4">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              selectTebs(e.target.checked ? "1" : "0")
                            }
                            checked={selectTeb == "1"}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div
                        className={
                          selectTeb == "0" ? "on_off_tab active" : "on_off_tab"
                        }
                        onClick={() => selectTebs("0")}
                      >
                        {/* <button
                      onClick={() => selectChain("1")}
                      className={selectTeb == "1" ? "active" : ""}
                    >
                      
                    </button> */}
                        <div className="row" id="people" ref={peopleUsRef}>
                          <div className="col-md-6 ">
                            <img
                              className=" img-fluid "
                              src="/img/cfp.png"
                              alt="cfp"
                            />
                          </div>
                          <div className="col-md-6 fs13">
                            <h3 className=" hadding2 ">
                              <span className="t_gr">CRUDO</span> for People:
                            </h3>
                            <div className="fw600 ttu">
                              THE FIRST BLOCKCHAIN LOYALTY PROGRAM FOR GAS
                              STATIONS
                            </div>
                            <ul className="list mt-2">
                              <li>
                                Introducing a loyalty program that rewards users
                                with CRUDO tokens for frequent gas purchases at
                                affiliated petrol stations.{" "}
                              </li>
                              <li>
                                Develop a system where users can earn bonus
                                tokens for consistent purchases, referrals, or
                                engaging with specific promotions.{" "}
                              </li>
                              <li>
                                Enable gas stations to customize their loyalty
                                offerings, such as discounts, freebies, or
                                exclusive deals for customers using CRUDO tokens
                                for payments.
                              </li>
                            </ul>
                            <a
                              className="btn mt-3"
                              target="_blank"
                              href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v1/for-people"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          selectTeb == "1" ? "on_off_tab active" : "on_off_tab"
                        }
                        onClick={() => selectTebs("1")}
                      >
                        <div className="row" id="business" ref={businessRef}>
                          <div className="col-md-6 ">
                            <img
                              className=" img-fluid "
                              src="/img/crudo_business.png"
                              alt="crudo business"
                            />
                          </div>
                          <div className="col-md-6 fs13">
                            <h3 className=" hadding2 ">
                              <span className="t_gr">CRUDO</span> for Business:
                            </h3>
                            <div className="fw600 ttu mb-2">
                              COMPREHENSIVE DATA AND RISK MANAGEMENT SOLUTIONS
                              FOR OIL&GAS BUSINESSES
                            </div>
                            Crudo users will have access to:
                            <ul className="list mt-2">
                              <li>Live commodities price updates</li>
                              <li>Ship AIS tracking</li>
                              <li>Companies Due Diligence reports </li>
                              <li>
                                Buyers, Vendors and Distributors looking to
                                establish new business relationships
                              </li>
                              <li>
                                Marketplace for enterprises in the Oil trading
                                industry.
                              </li>
                            </ul>
                            <a
                              className="btn mt-3"
                              target="_blank"
                              href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v1/for-business"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mr-auto text-center mo_none">
                    <img
                      className=" img-fluid "
                      src="/img/ecosystem.png"
                      alt="ecosystem"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section
              className=" p70"
              data-scroll-index={2}
              id="tokenomics"
              ref={tokenomicsRef}
            >
              <div className="container">
                <h2 className=" hadding "> Tokenomics </h2>
                <div className="row align-items-center mb-4 mb-md-5">
                  <div className="col-md-6">
                    <ul className="tokenomics_list">
                      <li>
                        <span className="t1" /> Presale (40%)
                      </li>
                      <li>
                        <span className="t2" /> Rewards (20%)
                      </li>
                      <li>
                        <span className="t3" /> ICO Bonus + bounty (10%)
                      </li>
                      <li>
                        <span className="t4" /> Marketing (10%)
                      </li>
                      <li>
                        <span className="t5" /> Ecosystem Operations (5%)
                      </li>
                      <li>
                        <span className="t6" /> Team (5%)
                      </li>
                      <li>
                        <span className="t7" /> Development and Tech Operations
                        (3%)
                      </li>
                      <li>
                        <span className="t8" /> Legal Advisors and Surveyors
                        (3%)
                      </li>
                      <li>
                        <span className="t9" />
                        Partnerships (2%)
                      </li>
                      <li>
                        <span className="t10" />
                        Liquidity and Exchange listings (2%)
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <img
                      src="/img/tokenomics.png"
                      alt="tokenomics"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="box2 d-md-flex justify-content-center text-center ">
                  <div className="token_contract_item">
                    <div className="h5 fw600">Contract Address</div>
                    <a
                      href="https://bscscan.com/address/0x3785b789C64F2fCaB1c35f72fc2BdEB43D2049C7#code"
                      target="_blank"
                      className="wbba color5"
                    >
                      {tokenAddress}
                    </a>
                  </div>
                  <div className="token_contract_item">
                    <div className="h5 fw600">Decimal</div>
                    <span>18</span>
                  </div>
                  <div className="token_contract_item">
                    <div className="h5 fw600">Network</div>
                    <span> Binance Smart Chain (BEP20)</span>
                  </div>
                  <div className="token_contract_item">
                    <div className="h5 fw600">Token Symbol</div>
                    <span>CRUDO</span>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="roadmap p70"
              data-scroll-index={4}
              id="roadmap"
              ref={roadmapRef}
            >
              <div className="container">
                <h4 className="hadding mo_block text-center mb-4">
                  <span>CRUDO</span> Roadmap
                </h4>
                <h3 class="hadding text-center mb-4  ">
                  <span>TOKEN LISTING ON CEX AT THE END OF STAGE 5</span>
                </h3>
                <div className="row  align-items-center">
                  <div className="col-md-3 text-right mo_none">
                    <img
                      className=" img-fluid road_map "
                      src="/img/road_map.png"
                      alt="road_map"
                    />
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="position-relative w85">
                      <div className="icon">
                        <span>
                          <img src="/img/phase1.png" alt="phase1" />
                        </span>
                      </div>
                      <div className="roadmap_line_box roadmap_line_box1">
                        <div className="roadmap_box0 roadmap_box1 "> </div>
                        <div className="roadmap_box0 roadmap_box2 "> </div>
                      </div>
                      <div className="box2 roadmap_box">
                        <span className="color1 h3 fw600 mb-1 d-block pr-5">
                          Quarter 1
                          {/* <span className="text-white">Q1-Q2 2024</span> */}
                        </span>

                        <small className="ttu h6 color1 fw600">
                          Foundations & Early Deliverables
                        </small>
                        <h5>January 2025: Crudo Wallet App Launch </h5>
                        <h6>Features: </h6>
                        <ul className="">
                          <li className="li_r ">
                            Secure wallet for Crudo Tokens and other supported
                            assets.
                          </li>
                          <li className="li_r ">
                            {" "}
                            Seamless token transfers and transaction history.
                          </li>
                          <li className="li_r ">
                            Multi-layer security, including biometrics and
                            encryption.
                          </li>
                        </ul>

                        <h5 className="mt-4">
                          March 2025: Crudo Marketplace Launch{" "}
                        </h5>
                        <h6>Features:</h6>
                        <ul className="">
                          <li className="li_r ">
                            {" "}
                            Decentralized marketplace for crude oil and related
                            services.
                          </li>
                          <li className="li_r ">
                            {" "}
                            Payments powered by Crudo Tokens.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Real-time pricing and trade matching.{" "}
                          </li>
                        </ul>

                        <h5 className="mt-4">
                          March 2025: Ship AIS Tracker Deployment
                        </h5>
                        <h6>Features:</h6>
                        <ul className="">
                          <li className="li_r ">
                            {" "}
                            Blockchain-powered vessel tracking.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Real-time updates on crude oil shipments.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Enhanced transparency in the supply chain.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="position-relative w85">
                      <div className="icon">
                        <span>
                          <img src="/img/phase2.png" alt="phase2" />
                        </span>
                      </div>
                      <div className="roadmap_line_box">
                        <div className="roadmap_box0 roadmap_box1 "> </div>
                        <div className="roadmap_box0 roadmap_box2 "> </div>
                      </div>
                      <div className="box2 roadmap_box">
                        <span className="color1 h3 fw600 mb-1 d-block pr-5">
                          Quarter 2
                        </span>

                        <small className="ttu h6 color1 fw600">
                          Expanding Trading Capabilities and Trust
                        </small>
                        <h5 className="mt-4">
                          May 2025: Binary Options for Crude Oil Trading
                        </h5>
                        <h6>Features:</h6>
                        <ul className="mt-3">
                          <li className="li_r ">
                            Simplified binary trading platform powered by Crudo
                            Tokens.
                          </li>
                          <li className="li_r ">
                            {" "}
                            Automated smart contract-based trade settlements.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Educational tools for new traders.
                          </li>
                        </ul>
                        <h5 className="mt-4">
                          June 2025: Buyers and Vendors Verification System
                        </h5>
                        <h6>Features:</h6>
                        <ul className="">
                          <li className="li_r ">
                            {" "}
                            Decentralized identity verification system.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Blockchain-backed KYC and AML compliance.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Enhanced trust and reduced fraud in the marketplace.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="position-relative w85">
                      <div className="icon">
                        <span>
                          <img src="/img/phase3.png" alt="phase3" />
                        </span>
                      </div>
                      <div className="roadmap_line_box roadmap_line_box3">
                        <div className="roadmap_box0 roadmap_box1 "> </div>
                        <div className="roadmap_box0 roadmap_box2 "> </div>
                      </div>
                      <div className="box2 roadmap_box">
                        <span className="color1 h3 fw600 mb-1 d-block pr-5">
                          Quarter 3
                        </span>
                        <small className="ttu h6 color1 fw600">
                          Advanced Analytics, Utility, and Blockchain
                          Integration
                        </small>
                        <h5 className="">
                          August 2025: Document Storage on Blockchain
                        </h5>
                        <h6>Features: </h6>
                        <ul className="mt-3">
                          <li className="li_r ">
                            {" "}
                            Immutable and secure storage for trade documents.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Instant sharing and verification within Crudo
                            Marketplace.{" "}
                          </li>
                        </ul>

                        <h5 className="mt-4">
                          August 2025: Document Storage on Blockchain
                        </h5>
                        <h6>Features:</h6>
                        <ul className="mt-3">
                          <li className="li_r ">
                            {" "}
                            Data-driven insights into terminal operations and
                            inventory levels.
                          </li>
                          <li className="li_r ">
                            {" "}
                            Real-time analytics for better decision-making.
                          </li>
                          <li className="li_r ">
                            Integration with Crudo Marketplace and Ship AIS
                            Tracker.{" "}
                          </li>
                        </ul>

                        <h5 className="mt-4">
                          September 2025: Crudo Loyalty & Payment Cards for Gas
                          Stations{" "}
                        </h5>
                        <h6>Features: </h6>
                        <ul className="mt-3">
                          <li className="li_r ">
                            {" "}
                            Blockchain-based loyalty rewards for frequent users.
                          </li>
                          <li className="li_r ">
                            Payment integration with Crudo Tokens.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Discounts and offers at partnered gas stations
                            worldwide.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="position-relative w85 mb-0">
                      <div className="icon">
                        <span>
                          <img src="/img/phase4.png" alt="phase4" />
                        </span>
                      </div>
                      <div className="roadmap_line_box roadmap_line_box4">
                        <div className="roadmap_box0 roadmap_box1 "> </div>
                        <div className="roadmap_box0 roadmap_box2 "> </div>
                      </div>
                      <div className="box2 roadmap_box">
                        <span className="color1 h3 fw600 mb-1 d-block  ">
                          Phase 4
                        </span>

                        <small className="ttu h6 color1 fw600">
                          System Optimization and Feedback Integration
                        </small>
                        <h5 className="">
                          October–December 2025: System Optimization and
                          Feedback Integration{" "}
                        </h5>
                        <h6>Features: </h6>
                        <ul className="mt-3">
                          <li className="li_r ">
                            {" "}
                            Collect user feedback for all deployed solutions.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Release updates and improvements based on community
                            and partner input.{" "}
                          </li>
                          <li className="li_r ">
                            {" "}
                            Plan for scaling Crudo Protocol in 2026, focusing on
                            ecosystem expansion and partnerships.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              // onClick={scrollTosignupRef}
              // ref={signupRef}
              // data-scroll-index={6}
              className="ex_box  sign_up_box"
              // id="sign-up"
            >
              <img src="/img/s1.png" alt="s1 " className="s1 s0" />
              <div className="container  position-relative  ">
                <div className=" p70 pb-0">
                  <img src="/img/s2.png" alt="s1 " className="s2 s0" />
                  <img src="/img/s3.png" alt="s1 " className="s3 s0" />
                  <img src="/img/s4.png" alt="s1 " className="s4 s0" />
                  <div className="ex_box_in position-relative">
                    <div className="row align-items-end">
                      <div className="col-md-6 pr-md-5 mb-4">
                        <div className="ex_box_in position-relative box2 pl-lg-5 pr-lg-5">
                          <div className="text-center lh70 mb-4">
                            <h6 className="h1 mb-0 pt-3 pt-md-0">
                              Signup Now
                              <br />
                              for CRUDO Airdrop
                            </h6>
                          </div>
                          <div className="form-group ex_input_box position-relative">
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter  User Name"
                              class="input_item"
                              value={name}
                              onChange={handleChange}
                            />
                            <span className="text-danger">{nameErr}</span>
                          </div>
                          <div className="form-group ex_input_box position-relative">
                            <input
                              type="text"
                              name="email"
                              placeholder="Enter  Email"
                              class="input_item"
                              value={email}
                              onChange={handleChange}
                            />
                            <span className="text-danger">{emailErr}</span>
                          </div>
                          <div className="form-group ex_input_box position-relative">
                            {/* <input
                          type="text"
                          placeholder="+234  800  2738  9700"
                          className="input_item pl-5"
                        />
                        <img
                          src="/img/japan.png"
                          alt="token "
                          style={{ position: "absolute", left: 18, top: 12 }}
                        /> */}
                            <PhoneInput
                              key={"phoneInput"}
                              country="IND"
                              value={mobile_number}
                              onChange={handleOnChanges}
                              className="input_item"
                              placeholder="Email/Mobile"
                              countryCodeEditable={false}
                              enableSearch={true}
                              inputProps={{
                                autoFocus: true,
                                name: "mobile_number",
                              }}
                              // disabled={disableGetCode}
                            />
                            <span className="text-danger">
                              {mobile_numberErr}
                            </span>
                          </div>
                          <div className>
                            <button
                              className="btn w100 ttu btn_black"
                              onClick={registerHandler}
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 m-auto ">
                        <img
                          src="/img/app.png"
                          alt="token "
                          className="img-fluid "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="faq p70 ">
              <div className="container ">
                <div className="row ">
                  <div className="col-lg-11 m-auto">
                    <div className="box2 p-md-5">
                      <h2 className="hadding mb-md-5 mb-4 text-center">
                        Frequently Asked Questions
                      </h2>
                      <div
                        className="accordion md-accordion style-2"
                        id="accordionEx"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingOne1"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseOne1"
                              aria-expanded="true"
                              aria-controls="collapseOne1"
                            >
                              What is the Crudo Protocol and how does it
                              revolutionize the commodities market?
                            </a>
                          </div>
                          <div
                            id="collapseOne1"
                            className="collapse "
                            role="tabpanel"
                            aria-labelledby="headingOne1"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              The Crudo Protocol is a platform leveraging
                              blockchain technology to transform the acquisition
                              and trading of commodities, focusing on crude oil.
                              It aims to offer a transparent, secure, and
                              efficient experience for investing in crude oil by
                              eliminating traditional complexities
                            </div>
                          </div>
                        </div>
                        {/* <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo2"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo2"
                              aria-expanded="false"
                              aria-controls="collapseTwo2"
                            >
                              What are the benefits of using CRUDO for payments
                              at partner gas stations, and how do users earn
                              rewards through this process?
                            </a>
                          </div>
                          <div
                            id="collapseTwo2"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo2"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Users benefit from using CRUDO for payments at
                              partner gas stations by earning rewards in CRUDO
                              tokens that can be reused within the ecosystem or
                              exchanged for FIAT.
                            </div>
                          </div>
                        </div> */}
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo3"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo3"
                              aria-expanded="false"
                              aria-controls="collapseTwo3"
                            >
                              How can token holders generate passive income by
                              staking Crudo tokens?
                            </a>
                          </div>
                          <div
                            id="collapseTwo3"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo3"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Token holders can generate passive income by
                              staking Crudo tokens, allowing them to earn
                              rewards through this process. Staking will be
                              introduced once CRUDO is listed on exchanges.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo4"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo4"
                              aria-expanded="false"
                              aria-controls="collapseTwo4"
                            >
                              How does CRUDO protocol work?
                            </a>
                          </div>
                          <div
                            id="collapseTwo4"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo2"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Crudo Protocol provides a platform and an
                              ecosystem to make real life investment in a
                              trillion dollar industry easier for people. Users
                              of the Crudo ecosystem can get access to a variety
                              of tools necessary in the Oil and Gas industry all
                              in one place.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo5"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo5"
                              aria-expanded="false"
                              aria-controls="collapseTwo5"
                            >
                              What is the utility of the CRUDO token?
                            </a>
                          </div>
                          <div
                            id="collapseTwo5"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo5"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Crudo token is the native token of CRUDO protocol
                              that will be listed on DEX and CEX and its utility
                              is fundamental for the development of the project
                              as all the transactions within the ecosystem are
                              exclusively made in CRUDO tokens. Users can also
                              pay at the petrol stations using Crudo tokens
                              gaining access to cashback and discounts. Read our
                              whitepaper to know more.
                            </div>
                          </div>
                        </div>

                        <h5 className="color1 ttu mt-4 mb-4">
                          presale questions
                        </h5>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo63"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo63"
                              aria-expanded="false"
                              aria-controls="collapseTwo63"
                            >
                              What payment methods are accepted for purchasing
                              CRUDO tokens?
                            </a>
                          </div>
                          <div
                            id="collapseTwo63"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo63"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              The platform currently accepts payments in ETH,
                              BNB and other cryptocurrencies. Check the How to
                              buy section of our website.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo64"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo64"
                              aria-expanded="false"
                              aria-controls="collapseTwo64"
                            >
                              Are there any bonuses for purchasing CRUDO tokens
                              during presale?
                            </a>
                          </div>
                          <div
                            id="collapseTwo64"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo64"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Yes, there is a bonus for early investors. Get in
                              touch with our team to find out if you are
                              eligible for the bonus.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo65"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo65"
                              aria-expanded="false"
                              aria-controls="collapseTwo65"
                            >
                              When do I receive my tokens?
                            </a>
                          </div>
                          <div
                            id="collapseTwo65"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo65"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              The tokens sold during the presale stages are
                              protected via vesting contract. Investors will
                              receive 10% of their tokens in Stage 5 and another
                              10% at Stage 10 before we launch on DEX. The rest
                              of the tokens are going to be airdropped on a
                              monthly basis. Check the whitepaper for the token
                              distribution release.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo66"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo66"
                              aria-expanded="false"
                              aria-controls="collapseTwo66"
                            >
                              How long is the presale?
                            </a>
                          </div>
                          <div
                            id="collapseTwo66"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo66"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              The presale is structured into 5 rounds with the
                              price of the tokens set to increase after every
                              single round sells out.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo67"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo67"
                              aria-expanded="false"
                              aria-controls="collapseTwo67"
                            >
                              Are my tokens vested?
                            </a>
                          </div>
                          <div
                            id="collapseTwo67"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo67"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              Yes, the tokens are vested. Check the dedicated
                              section of the whitepaper to know more.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo68"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo68"
                              aria-expanded="false"
                              aria-controls="collapseTwo68"
                            >
                              Is there any tax added to the token?
                            </a>
                          </div>
                          <div
                            id="collapseTwo68"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo68"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              No buy or sell tax is applicable to your tokens.
                            </div>
                          </div>
                        </div>
                        {/* <div className="card">
                      <div className="card-header" role="tab" id="headingTwo69">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordionEx"
                          href="#collapseTwo69"
                          aria-expanded="false"
                          aria-controls="collapseTwo69"
                        >
                          What is the minimum purchase amount?
                        </a>
                      </div>
                      <div
                        id="collapseTwo69"
                        className="collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo69"
                        data-parent="#accordionEx"
                      >
                        <div className="card-body">
                          The minimum purchase amount for CRUDO tokens is $50.
                          However, the fractionalised NFTs will have a starting
                          price of the equivalent of 250$ in CRUDO tokens.
                        </div>
                      </div>
                    </div> */}
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo610"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo610"
                              aria-expanded="false"
                              aria-controls="collapseTwo610"
                            >
                              Can I receive my tokens in my CEX wallet?
                            </a>
                          </div>
                          <div
                            id="collapseTwo610"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo610"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              No, CRUDO tokens must be received in a DeFi wallet
                              to ensure compatibility with the token's
                              architecture. Once CRUDO will be listed on a
                              centralised exchange you’ll be able to hold your
                              tokens there.
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo611"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo611"
                              aria-expanded="false"
                              aria-controls="collapseTwo611"
                            >
                              What is the maximum amount I can purchase?
                            </a>
                          </div>
                          <div
                            id="collapseTwo611"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo611"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              To protect our investors we have set a minimum
                              personal cap of 0.1 BNB and a maximum personal cap
                              of 100 BNBs worth of tokens bought during presale.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            role="tab"
                            id="headingTwo612"
                          >
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionEx"
                              href="#collapseTwo612"
                              aria-expanded="false"
                              aria-controls="collapseTwo612"
                            >
                              What network is Crudo Protocol deployed on?
                            </a>
                          </div>
                          <div
                            id="collapseTwo612"
                            className="collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo612"
                            data-parent="#accordionEx"
                          >
                            <div className="card-body">
                              The CRUDO token is deployed on the Binance Smart
                              Chain.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};
